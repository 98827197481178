import {createRouter, createWebHistory} from 'vue-router';
import HomeView from '../views/HomeView.vue';
import NotFound from '../views/NotFoundView.vue';
import app from "@/main";
import deleteNonValidCookie from "@/hooks/deleteNonValidCookie";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {top: 0}
        }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView,
            beforeEnter: (from, to, next) => {
                next({name: 'booking'});
            }
        },
        {
            path: '/booking/',
            component: () => import('../views/booking/BookingRouterView.vue'),
            children: [
                {
                    path: '',
                    name: 'booking',
                    component: () => import('../views/booking/BookingView.vue'),
                    meta: {
                        title: 'Бронирование | Маркет Газпром Поляна',
                        description: 'Список номеров'
                    },
                },
                /*{
                    path: 't',
                    name: 'booking_t',
                    component: () => import('../views/booking/BookingView.vue'),
                    meta: {
                        title: 'Бронирование | Маркет Газпром Поляна',
                        description: 'Список номеров'
                    },
                },*/
                /*{
                    path: ':name',
                    name: 'booking.show',
                    component: () => import('../views/booking/BookingItemView.vue'),
                    meta: {
                        title: 'Бронирование | Маркет Газпром Поляна', //route => route.params.name + ' | Кинотеатр | Маркет Газпром Поляна',
                        description: 'Бронирование. О номере'
                    }
                },*/
            ]
        },
        /*{
            path: '/ski-pass',
            name: 'ski-pass',
            component: () => import('../views/SkiPassView.vue'),
            meta: {
                title: 'Ski-pass 2.0 | Маркет Газпром Поляна',
                description: 'Продажа ски-пассов'
            }
        },*/
        {
            path: '/sprint/',
            name: 'sprint',
            component: () => import('../views/SprintView.vue'),
            meta: {
                title: 'Спринт | Маркет Газпром Поляна',
                description: 'Спринт'
            }
        },
        {
            path: '/abonements/',
            name: 'abonements',
            component: () => import('../views/new/AbonementsView.vue'),
            meta: {
                title: 'Многодневные ски-пассы | Маркет Газпром Поляна',
                description: 'Продажа абонементов'
            }
        },
        {
            path: '/ski-pass/',
            name: 'ski-pass',
            component: () => import('../views/new/SkiPassView.vue'),
            meta: {
                title: 'Однодневные ски-пассы | Маркет Газпром Поляна',
                description: 'Продажа ски-пассов'
            }
        },
        {
            path: '/tariffs-ul/',
            name: 'tariffs-ul',
            component: () => import('../views/TariffsULView.vue'),
            meta: {
                title: 'Тарифы ЮЛ | Маркет Газпром Поляна',
                description: 'Тарифы ЮЛ'
            }
        },
        {
            path: '/ozon/',
            name: 'ozon',
            component: () => import('../views/OzonView.vue'),
            meta: {
                title: 'OZON | Маркет Газпром Поляна',
                description: 'Продажа через OZON'
            }
        },
        {
            path: '/walking/',
            component: () => import('../views/walking/WalkingRouterView.vue'),
            children: [
                {
                    path: '',
                    name: 'walking',
                    component: () => import('../views/walking/WalkingView.vue'),
                    meta: {
                        title: 'Прогулочные билеты | Маркет Газпром Поляна',
                        description: 'Продажа прогулочных билетов'
                    }
                },
                {
                    path: ':id',
                    name: 'walking.show',
                    component: () => import('../views/walking/WalkingItemView.vue'),
                    meta: {
                        title: 'Прогулочные билеты | Маркет Газпром Поляна',
                        description: 'Продажа прогулочных билетов'
                    }
                },
            ]
        },
        {
            path: '/combo/',
            component: () => import('../views/combo/ComboRouterView.vue'),
            children: [
                {
                    path: '',
                    name: 'combo',
                    component: () => import('../views/combo/ComboView.vue'),
                    meta: {
                        title: 'Комбинированные услуги | Маркет Газпром Поляна',
                        description: 'Продажа комбинированных услуг'
                    }
                },
                {
                    path: ':id',
                    name: 'combo.show',
                    component: () => import('../views/combo/ComboItemView.vue'),
                    meta: {
                        title: 'Комбинированные услуги | Маркет Газпром Поляна',
                        description: 'Продажа комбинированных услуг'
                    }
                },
            ]
        },
        {
            path: '/aquapark/',
            name: 'aquapark',
            component: () => import('../views/new/AquaparkView.vue'),
            meta: {
                title: 'Аквапарк | Маркет Газпром Поляна',
                description: 'Продажа билетов в аквапарк'
            }
        },
        {
            path: '/discounts/',
            name: 'discounts',
            component: () => import('../views/DiscountView.vue'),
            meta: {
                title: 'Мои скидки | Маркет Газпром Поляна',
                description: 'Персональные скидки на ски-пассы'
            }
        },
        {
            path: '/orders/',
            name: 'orders',
            component: () => import('../views/orders/OrdersView.vue'),
            meta: {
                title: 'Мои заказы | Маркет Газпром Поляна',
                description: 'Список заказов',
                authOnly: true
            }
        },
        {
            path: '/orders/:order',
            name: 'orders.show',
            component: () => import('../views/orders/OrdersItemView.vue'),
            meta: {
                title: 'Мои заказ | Маркет Газпром Поляна',
                description: 'Мои заказ',
                authOnly: true
            }
        },
        {
            path: '/orders-ul/',
            name: 'orders-ul',
            component: () => import('../views/orders/OrdersULView.vue'),
            meta: {
                title: 'Заказы ЮЛ | Маркет Газпром Поляна',
                description: 'Список заказов ЮЛ',
                authOnly: true
            }
        },
        /*{
            path: '/profile/',
            name: 'profile',
            component: () => import('../views/ProfileView.vue'),
            meta: {
                title: 'Профиль | Маркет Газпром Поляна',
                description: 'Профиль',
                authOnly: true
            }
        },*/
        {
            path: '/profile/',
            component: () => import('../views/profile/ProfileRouterView.vue'),
            children: [
                {
                    path: '',
                    name: 'profile',
                    component: () => import('../views/profile/MyDataView.vue'),
                    meta: {
                        title: 'Личный кабинет - Мои данные | Маркет Газпром Поляна',
                        description: 'Личный кабинет'
                    }
                },
                {
                    path: 'loyalty',
                    name: 'profile.loyalty',
                    component: () => import('../views/profile/LoyaltyView.vue'),
                    meta: {
                        title: 'Личный кабинет - Программа лояльности | Маркет Газпром Поляна',
                        description: 'Личный кабинет'
                    }
                },
                {
                    path: 'gazprom',
                    name: 'profile.gazprom',
                    component: () => import('../views/profile/GazpromView.vue'),
                    meta: {
                        title: 'Личный кабинет - Работникам ПАО «Газпром» | Маркет Газпром Поляна',
                        description: 'Личный кабинет'
                    }
                },
            ]
        },
        {
            path: '/transfer/',
            name: 'transfer',
            component: () => import('../views/TransferView.vue'),
            meta: {
                title: 'Транспортные услуги | Маркет Газпром Поляна',
                description: 'Транспортные услуги'
            }
        },
        {
            path: '/parking/',
            name: 'parking',
            component: () => import('../views/ParkingView.vue'),
            meta: {
                title: 'Оплата парковки | Маркет Газпром Поляна',
                description: 'Оплата парковки'
            }
        },
        {
            path: '/cinema/',
            component: () => import('../views/cinema/CinemaRouterView.vue'),
            children: [
                {
                    path: '',
                    name: 'cinema',
                    component: () => import('../views/cinema/CinemaView.vue'),
                    meta: {
                        title: 'Кинотеатр | Маркет Газпром Поляна',
                        description: 'Кинотеатры. Ближайшие сеансы'
                    },
                },
                {
                    path: ':name',
                    name: 'cinema.show',
                    component: () => import('../views/cinema/CinemaItemView.vue'),
                    meta: {
                        title: 'Кинотеатр | Маркет Газпром Поляна', //route => route.params.name + ' | Кинотеатр | Маркет Газпром Поляна',
                        description: 'Кинотеатры. Фильм'
                    }
                },
            ]
        },
        {
            path: '/restaurants/',
            component: () => import('../views/restaurants/RestaurantRouterView.vue'),
            children: [
                {
                    path: '',
                    name: 'restaurants',
                    component: () => import('../views/restaurants/RestaurantView.vue'),
                    meta: {
                        title: 'Рестораны | Маркет Газпром Поляна',
                        description: 'Список ресторанов'
                    },
                },
                {
                    path: ':name',
                    name: 'restaurants.show',
                    component: () => import('../views/restaurants/RestaurantItemView.vue'),
                    meta: {
                        title: 'Рестораны | Маркет Газпром Поляна', //route => route.params.name + ' | Кинотеатр | Маркет Газпром Поляна',
                        description: 'Рестораны. О ресторане'
                    }
                },
            ]
        },
        /*{
            path: '/services/',
            component: () => import('../views/services/ServiceRouterView.vue'),
            children: [
                {
                    path: '',
                    name: 'services',
                    component: () => import('../views/services/ServiceView.vue'),
                    meta: {
                        title: 'Услуги | Маркет Газпром Поляна',
                        description: 'Список услуг'
                    },
                },
                {
                    path: ':id',
                    name: 'services.show',
                    component: () => import('../views/services/ServiceItemView.vue'),
                    meta: {
                        title: 'Услуги | Маркет Газпром Поляна', //route => route.params.name + ' | Кинотеатр | Маркет Газпром Поляна',
                        description: 'Услуга. Об услуге'
                    }
                },
            ]
        },*/
        {
            path: '/events/',
            component: () => import('../views/events/EventsRouterView.vue'),
            children: [
                {
                    path: '',
                    name: 'events',
                    component: () => import('../views/events/EventsView.vue'),
                    meta: {
                        title: 'Мероприятия | Маркет Газпром Поляна',
                        description: 'Список мероприятий'
                    },
                },
                {
                    path: ':id',
                    name: 'events.show',
                    component: () => import('../views/events/EventsItemView.vue'),
                    meta: {
                        title: 'Мероприятия | Маркет Газпром Поляна', //route => route.params.name + ' | Кинотеатр | Маркет Газпром Поляна',
                        description: 'Мероприятие. О мероприятие'
                    }
                },
            ]
        },
        {
            path: '/spa/',
            component: () => import('../views/spa/SpaRouterView.vue'),
            children: [
                {
                    path: '',
                    name: 'spa',
                    component: () => import('../views/spa/SpaView.vue'),
                    meta: {
                        title: 'Бассейны и СПА-услуги | Маркет Газпром Поляна',
                        description: 'Бассейны и СПА-услуги'
                    },
                },
                /*{
                    path: ':id',
                    name: 'spa.show',
                    component: () => import('../views/spa/SpaItemView.vue'),
                    meta: {
                        title: 'Бассейны и СПА-услуги | Маркет Газпром Поляна',
                        description: 'Бассейны и СПА-услуги. Об услуге'
                    }
                },*/
            ]
        },
        {
            path: '/special/',
            name: 'special',
            component: () => import('../views/special/SpecialView.vue'),
            meta: {
                title: 'Льготы | Маркет Газпром Поляна',
                description: 'Льготы'
            },
        },
        {
            path: '/tickets/payment/success',
            name: 'tickets.payment.success',
            component: () => import('../views/tickets/payment/PaymentSuccessView.vue'),
            meta: {
                title: 'Успешная оплата | Маркет Газпром Поляна',
                description: 'Успешная оплата',
                //singlePage: true
            }
        },
        {
            path: '/tickets/payment/fail',
            name: 'tickets.payment.fail',
            component: () => import('../views/tickets/payment/PaymentFailView.vue'),
            meta: {
                title: 'Ошибка оплаты | Маркет Газпром Поляна',
                description: 'Ошибка оплаты',
                //singlePage: true
            }
        },
        {
            path: '/tickets/',
            name: 'tickets',
            component: () => import('../views/tickets/TicketsView.vue'),
            meta: {
                title: 'Мероприятия | Маркет Газпром Поляна',
                description: 'Список мероприятий'
            },
        },
        {
            path: '/tickets/:id',
            name: 'tickets.show',
            component: () => import('../views/tickets/TicketsItemView.vue'),
            meta: {
                title: 'Оформление билетов | Маркет Газпром Поляна',
                description: 'Оформление билетов',
            }
        },
        {
            path: '/payment/success',
            name: 'payment.success',
            component: () => import('../views/payment/PaymentSuccessView.vue'),
            meta: {
                title: 'Успешная оплата | Маркет Газпром Поляна',
                description: 'Успешная оплата',
                //singlePage: true
            }
        },
        {
            path: '/payment/fail',
            name: 'payment.fail',
            component: () => import('../views/payment/PaymentFailView.vue'),
            meta: {
                title: 'Ошибка оплаты | Маркет Газпром Поляна',
                description: 'Ошибка оплаты',
                //singlePage: true
            }
        },
        {
            path: '/delete-account',
            name: 'delete-account',
            component: () => import('../views/DeleteAccountView.vue'),
            meta: {
                title: 'Запрос на удаление персональных данных',
                description: '',
            }
        },
        {
            path: '/temp/test',
            name: 'temp.test',
            component: () => import('../views/temp/TestView.vue'),
            meta: {
                title: 'Тест',
                description: '',
            }
        },
        {
            path: '/msid',
            name: 'msid',
            component: () => import('../views/MSidView.vue'),
            meta: {
                title: 'Сид',
                description: '',
            }
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'page404',
            component: NotFound,
            meta: {
                title: 'Страница не найдена',
                description: '',
            }
        },
    ]
});

const rNames = [
    'booking',
    'abonements',
    'ski-pass',
    'sprint',
    'walking',
    'aquapark',
    'restaurants',
    'events',
    'spa',
    'cinema',
    'special',
    'transfer',
    'parking',
    'orders',
    'orders-ul',
    'profile',
    'booking',
];

/*const Promo = usePromo();

router.beforeResolve(async (to, from, next) => {
    const newQuery = {...to.query};

    const promoCodes = app.config.globalProperties.$promoCodes;
    console.log('beforeResolve', promoCodes)
    // Проверяем наличие параметра и удаляем его
    if (newQuery.promo) {
        await Promo.add(newQuery.promo, to);
        delete newQuery.promo;
        // Используем next() с новым объектом для обновления URL без параметра
        return next({path: to.path, query: newQuery, replace: true});
    } else if (!Array.isArray(promoCodes)) {
        const {data} = await Promo.list();
        app.config.globalProperties.$promoCodes = data || [];
    }

    return next();
})*/

router.beforeEach((to, from) => {
    deleteNonValidCookie();

    const serverRouters = app.config.globalProperties.$serverRouters;
    const currentServerRoute = serverRouters.find(item => item.tagRoute === to.name);

    if (~rNames.findIndex(itemRName => itemRName === to.name) && (!currentServerRoute || (currentServerRoute.isDebug && !app.config.globalProperties.$cookies.isKey("testMode")))) {
        router.addRoute({path: to.path, name: to.name + '404', component: NotFound});
        return {name: to.name + '404'};
    }


    if (to.meta && to.meta.title instanceof Function) {
        document.title = to.meta.title(to);
    } else {
        document.title = to.meta.title ? to.meta.title : "Маркет Газпром Поляна"
    }
});

router.afterEach((to, from) => {
    deleteNonValidCookie();
})

export default router
